// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-modern-slavery-statement-js": () => import("./../src/pages/modern-slavery-statement.js" /* webpackChunkName: "component---src-pages-modern-slavery-statement-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-service-customer-experience-js": () => import("./../src/pages/service/customer-experience.js" /* webpackChunkName: "component---src-pages-service-customer-experience-js" */),
  "component---src-pages-service-devops-js": () => import("./../src/pages/service/devops.js" /* webpackChunkName: "component---src-pages-service-devops-js" */),
  "component---src-pages-service-software-engineering-js": () => import("./../src/pages/service/software-engineering.js" /* webpackChunkName: "component---src-pages-service-software-engineering-js" */),
  "component---src-pages-thoughts-on-programming-js": () => import("./../src/pages/thoughts-on-programming.js" /* webpackChunkName: "component---src-pages-thoughts-on-programming-js" */),
  "component---src-templates-job-posting-js": () => import("./../src/templates/job-posting.js" /* webpackChunkName: "component---src-templates-job-posting-js" */),
  "component---src-templates-thoughts-on-programming-post-js": () => import("./../src/templates/thoughts-on-programming-post.js" /* webpackChunkName: "component---src-templates-thoughts-on-programming-post-js" */)
}

