module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jugendstil_IO","short_name":"J_","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"asset/jugendstil_io_icon_colored.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-114682443-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-drift/gatsby-browser.js'),
      options: {"plugins":[],"appId":"8eii5mpe6kt2"},
    }]
